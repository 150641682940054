import { Rgb } from "@app/rgb";

export type AutofillState = "disabled" | "enabledAll" | { enabledWith: string[] };

export type IdMapUpdate<K, V> = { update: V } | { remove: K };

export interface ResolvedListResource extends UnregisteredResource {
	resourceId: string;
	cropping: CropInformation | undefined;
}

export interface CropInformation {
	color: Rgb;
	threshold: number;
}

export interface ListShort {
	id: string;
	name: string;
	kind: ListKind;
}

export interface FfmpegResourceBase {
	startTimestamp: number | null;
	endTimestamp: number | null;
}

export interface YoutubeFfmpegResource extends FfmpegResourceBase {
	youtube: string;
}

export interface UrlFfmpegResource extends FfmpegResourceBase {
	url: string;
}

export type FfmpegResource = YoutubeFfmpegResource | UrlFfmpegResource;

export interface SpotifyResource {
	trackId: string;
}

export interface VibrancyColor {
	color: Rgb;
	population: number;
}

export interface Vibrancy {
	dark: VibrancyColor | null;
	darkMuted: VibrancyColor | null;
	light: VibrancyColor | null;
	lightMuted: VibrancyColor | null;
	muted: VibrancyColor | null;
	primary: VibrancyColor | null;
}

export interface Thumbnail {
	url: string;
	image: string;
	vibrancy: Vibrancy | null;
}

export type QueueType = "autofill" | "enqueue" | "front";
export type SubResource = "spotify" | "ffmpeg";

export interface ResolvedResource {
	resourceId: string;
	name: string;
	spotify: SpotifyResource | null;
	ffmpeg: FfmpegResource | null;
}

export interface UnregisteredResource {
	name: string;
	spotify: SpotifyResource | null;
	ffmpeg: FfmpegResource | null;
	priority: SubResource | null;
	resourceId: string | null;
}

export interface ResolvedSearchResource extends ResolvedListResource {
	playlists: string[];
}

export interface ResolvedSampleResource extends ResolvedSearchResource {
	playlist: string;
}

export interface QueueItem {
	id: string;
	issuerId: string | null;
	playlistId: string | null;
	containingPlaylists: string[] | null;
	selectedPlaylist?: string;
	resource: ResolvedResource | null;
	queueType: QueueType;
	subResource: SubResource | null;
}

export interface PlayerItem {
	id: string;
	issuerId: string | null;
	playlistId: string | null;
	resource: ResolvedResource;
	containingPlaylists: string[] | null;
	queueType: QueueType;
	subResource: SubResource;
	thumbnail: Thumbnail | null;
	startTimestamp: number;
	endTimestamp: number | null;
	startTime: string;
}

export interface User {
	id: string;
	name: string;
}

export interface UserVoteProgress {
	voters: number;
	needed: number;
	selfVoted: boolean;
}

export interface VoteData {
	id: string;
	vote: VoteType;
	progress: UserVoteProgress;
}

export interface PlayingData {
	item: PlayerItem;
	timestamp: number;
}

export type LoadError =
	| "noSubResource"
	| "ffmpeg"
	| "spotifyPlayer"
	| "youtubeDl"
	| "spotify"
	| { noMatchingSubResource: SubResource };

export namespace Initials {
	export interface Autofill {
		state: AutofillState;
		user: string | null;
	}

	export interface Listeners {
		listeners: string[];
	}

	export type Lists = ListShort[];

	export interface Player {
		item: PlayingData | null;
		paused: boolean;
	}

	export interface Queue {
		items: QueueItem[];
	}

	export interface RecentlyPlayed {
		items: QueueItem[];
	}

	export type Users = User[];

	export type Vote = VoteData | null;
}

export namespace Updates {
	export interface Autofill {
		change: AutofillState;
		user: string;
	}

	export type Lists = IdMapUpdate<string, ListShort>;

	export type Player = "pause" | "resume" | "endOfResource" | { play: PlayingData };

	export type Queue =
		| "pop"
		| { front: { item: QueueItem } }
		| { enqueue: { item: QueueItem } }
		| { removeAt: { offset: number } };

	export interface RecentlyPlayed {
		items: QueueItem[];
	}

	export type Users = User[];

	export type Vote = "finish" | "remove" | { start: VoteData } | { progress: UserVoteProgress };
}

export interface Request {
	url: string;
	method: string;
	data?: unknown;
	accept?: string;
}

const APPLICATION_JSON = "application/json";

export function request(url: string, method: string, data?: string, accept?: string): Promise<Response> {
	const headers: HeadersInit = { "content-type": APPLICATION_JSON };
	if (accept !== undefined) {
		headers["Accept"] = accept;
	}

	const request = new Request(url, {
		method,
		headers,
		body: data,
	});
	return window.fetch(request).then(
		x => {
			if (x.ok) {
				return Promise.resolve(x);
			}
			return Promise.reject(x);
		},
		r => {
			console.log(r);
			return Response.json({});
		},
	);
}

export function apiRequest({ data, method, url, accept }: Request): Promise<Response> {
	return request(`api/${url}`, method, data === undefined ? data : JSON.stringify(data), accept);
}

function json<T>(p: Promise<Response>): Promise<T> {
	return p.then(r => r.json() as T);
}

function empty(p: Promise<Response>): Promise<void> {
	return p.then(() => {
		return;
	});
}

export interface GetRequest {
	url: string;
	data?: Record<string, string>;
	accept?: string;
}

export function apiRequestGet({ url, data, accept }: GetRequest): Promise<Response> {
	return apiRequest({
		method: "GET",
		url: data === undefined ? url : url + "?" + new URLSearchParams(data),
		accept,
	});
}

export const resourceTranslateError = {
	"resource-not-found": "Resource not found",
	"duplicate-resource": "Duplicate resource",
	"duplicate-ffmpeg": "This ffmpeg subresource is already associated with another resource",
	"duplicate-spotify": "This spotify subresource is already associated with another resource",
	"empty-resource": "Resource would be empty after this action",
	"invalid-merge": "Invalid merge, the resources do not complement each other",
	"invalid-replace": "Can't merge a resource with itself",
};

export const listTranslateError = {
	"playlist-bad-id": "Playlist not found",
	"duplicate-playlist-id": "Duplicate playlist id",
	"playlist-duplicate-resource": "Duplicate resource in playlist",
	"out-of-bounds": "Index out of bounds",
	"internal-error": "Internal error",
	"resource-did-not-match": "Resource did not match",
	"no-permission": "Permission denied",
};

export const userTranslateError = {
	"user-not-found": "User not found",
	"duplicate-user": "Duplicate user",
	"internal-error": "Internal error",
	"invalid-password": "Invalid password",
	"insecure-password": "Insecure password",
	"unknown-login-name": "Unknown login name",
	"duplicate-login-name": "Duplicate login name",
};

export const queueTranslateError = {
	"loader-youtube-dl-failed": "Youtube-Dl failed to load the track",
	"loader-ffmpeg-error": "A ffmpeg error occured",
	"loader-spotify-error": "Failed to access spotify API",
};

export class SpotifyResourceSource {
	private spotifyTrackId: string;

	constructor(spotifyTrackId: string) {
		this.spotifyTrackId = spotifyTrackId;
	}
}

export class ResourceIdResourceSource {
	private resourceId: string;

	constructor(resourceId: string) {
		this.resourceId = resourceId;
	}
}

export class YoutubeResourceSource {
	private query: {
		youtubeId: string;
		startTimestamp?: number;
		endTimestamp?: number;
	};

	constructor(youtubeId: string, startTimestamp?: number, endTimestamp?: number) {
		this.query = { youtubeId, startTimestamp, endTimestamp };
	}
}

export class UrlResourceSource {
	private query: {
		url: string;
		startTimestamp?: number;
		endTimestamp?: number;
	};

	constructor(url: string, startTimestamp?: number, endTimestamp?: number) {
		this.query = { url, startTimestamp, endTimestamp };
	}
}

export type ResourceSource =
	| SpotifyResourceSource
	| ResourceIdResourceSource
	| YoutubeResourceSource
	| UrlResourceSource;

export class ResourcePlayResource {
	private resource: {
		resourceId: string;
		listId: string;
		subResource?: SubResource;
	};

	constructor(resourceId: string, listId: string, subResource?: SubResource) {
		this.resource = { resourceId, listId, subResource };
	}
}

export type PlayResourceSource =
	| SpotifyResourceSource
	| ResourcePlayResource
	| YoutubeResourceSource
	| UrlResourceSource;

export type ListKind = "normal" | "genre";

export interface ListProtectedEditors {
	protected: string[];
}

export type ListEditors = "public" | ListProtectedEditors;
export type VoteType = "pause" | "resume" | "skip";

export interface Permissions {
	admin: boolean;
	front: boolean;
	playlist: boolean;
}

export type ResourcePriority = "spotify" | "ffmpeg" | null;

export interface FullUser {
	id: string;
	name: string;
	loginName: string;
	permissions?: Permissions;
}

export interface ListGet {
	name: string;
	owner: string;
	kind: ListKind;
	editors: ListEditors;
	count: number;
}

export interface ListGetItems {
	items: ResolvedListResource[];
	range: { start: number; end: number };
}

export interface SearchResult {
	name: string;
}

export interface UrlSearchResult extends SearchResult {
	id: string;
	url: string;
}

export interface SearchData {
	resources: SearchResult[];
}

export type MergeSource = "a" | "b";

export type MetadataKind = "youtube" | "spotify" | "soundcloud";

export interface Metadata {
	name: string;
	thumbnail_url: string;
	kind: MetadataKind;
}

export const requests = {
	auth: (loginData: { password: string; loginName: string }) =>
		apiRequest({
			url: "auth",
			method: "POST",
			data: loginData,
		}),

	register: (registerData: { loginName: string; name: string; password: string }) =>
		apiRequest({
			url: "register",
			method: "POST",
			data: registerData,
		}),

	lists: () =>
		apiRequestGet({
			url: "lists",
			accept: "json",
		}),

	resources: (): Promise<{ resources: string[] }> =>
		apiRequestGet({
			url: "resources",
		}).then(r => r.json()),

	updates: () =>
		apiRequestGet({
			url: "updates",
		}).then(r => r.json()),

	autofill: {
		enable: (lists: string[]) =>
			apiRequest({
				url: "autofill/enable",
				method: "POST",
				data: {
					lists: lists,
				},
			}),

		enableAll: () =>
			apiRequest({
				url: "autofill/enable",
				method: "POST",
				data: "all",
			}),

		disable: () =>
			apiRequest({
				url: "autofill/disable",
				method: "POST",
			}),
	},

	list: {
		changeInfo: (id: string, name: string, kind: ListKind, editors: ListEditors) =>
			apiRequest({
				url: "list/change-info",
				method: "POST",
				data: {
					listId: id,
					name: name,
					kind: kind,
					editors: editors,
				},
			}),
		changeOwner: (id: string, newOwner: string) =>
			apiRequest({
				url: "list/change-owner",
				method: "POST",
				data: {
					listId: id,
					owner: newOwner,
				},
			}),
		create: (name: string, kind: ListKind, editors: ListEditors) =>
			apiRequest({
				url: "list/create",
				method: "POST",
				data: {
					name: name,
					kind: kind,
					editors: editors,
				},
			}),
		get: (id: string): Promise<ListGet> =>
			json(
				apiRequestGet({
					url: "list/get",
					data: {
						listId: id,
					},
					accept: APPLICATION_JSON,
				}),
			),
		remove: (id: string) =>
			apiRequest({
				url: "list/remove",
				method: "DELETE",
				data: {
					listId: id,
				},
			}),
		items: {
			add: (id: string, source: ResourceSource) =>
				apiRequest({
					url: "list/items/add",
					method: "POST",
					data: {
						listId: id,
						...source,
					},
				}),
			get: (id: string, start: number, end?: number): Promise<ListGetItems> =>
				json(
					apiRequestGet({
						url: "list/items/get",
						data: {
							listId: id,
							start: String(start),
							end: String(end),
						},
						accept: APPLICATION_JSON,
					}),
				),
			remove: (id: string, resourceId: string, index?: number) =>
				apiRequest({
					url: "list/items/remove",
					method: "DELETE",
					data: {
						listId: id,
						resourceId: resourceId,
						index: index,
					},
				}),
			removeAll: (id: string, resourceIds: string[]) =>
				apiRequest({
					url: "list/items/remove-all",
					method: "DELETE",
					data: {
						listId: id,
						resourceIds: resourceIds,
					},
				}),
		},
	},

	player: {
		audio: () =>
			apiRequestGet({
				url: "player/audio",
			}),
		enqueue: (source: PlayResourceSource) =>
			empty(
				apiRequest({
					url: "player/enqueue",
					method: "POST",
					data: source,
				}),
			),
		front: (source: PlayResourceSource) =>
			empty(
				apiRequest({
					url: "player/front",
					method: "POST",
					data: source,
				}),
			),
		remove: (id: string, index: number) =>
			empty(
				apiRequest({
					url: "player/remove",
					method: "DELETE",
					data: {
						itemId: id,
						index: index,
					},
				}),
			),
		next: (id: string) =>
			empty(
				apiRequest({
					url: "player/next",
					method: "POST",
					data: {
						itemId: id,
					},
				}),
			),
		addVote: (id: string) =>
			empty(
				apiRequest({
					url: "player/vote",
					method: "POST",
					data: {
						addVote: id,
					},
				}),
			),
		startVote: (vote: VoteType) =>
			empty(
				apiRequest({
					url: "player/vote",
					method: "POST",
					data: {
						start: vote,
					},
				}),
			),
		removeVote: (id: string) =>
			empty(
				apiRequest({
					url: "player/vote",
					method: "POST",
					data: {
						removeVote: id,
					},
				}),
			),
	},

	resource: {
		get: (source: ResourceSource[]): Promise<ResolvedSearchResource[]> =>
			apiRequest({
				url: "resource/get",
				method: "POST",
				data: source,
				accept: APPLICATION_JSON,
			}).then(r => r.json()),
		change: (
			id: string,
			name: string,
			priority: ResourcePriority | null,
			ffmpeg: { url: string; startTimestamp?: number; endTimestamp?: number } | null,
			spotify: string | null,
			cropping: CropInformation | undefined,
		) =>
			apiRequest({
				url: "resource/change",
				method: "POST",
				data: {
					id,
					name,
					ffmpeg,
					spotify,
					priority,
					cropping,
				},
			}),
		merge: (
			a: string,
			b: string,
			name: MergeSource,
			ffmpeg: MergeSource,
			spotify: MergeSource,
			priority: MergeSource,
			cropping: MergeSource,
		) =>
			empty(
				apiRequest({
					method: "POST",
					url: "resource/merge",
					data: {
						a,
						b,
						name,
						ffmpeg,
						spotify,
						priority,
						cropping,
					},
				}),
			),
		sample: (
			lists: string[],
			sampleCount: number,
		): Promise<{
			resources: ResolvedSampleResource[];
		}> =>
			json(
				apiRequest({
					url: "resource/sample",
					method: "POST",
					data: {
						lists: lists,
						samples: sampleCount,
					},
					accept: APPLICATION_JSON,
				}),
			),
		metadata: (urls: string[]): Promise<(Metadata | null)[]> =>
			json(
				apiRequest({
					url: "resource/metadata",
					method: "POST",
					data: urls,
					accept: APPLICATION_JSON,
				}),
			),
	},

	search: {
		resource: (query: string, limit: number): Promise<SearchData> =>
			json(
				apiRequestGet({
					url: "search/resource",
					data: {
						query: query,
						limit: String(limit),
					},
					accept: APPLICATION_JSON,
				}),
			),
		spotify: (query: string, limit: number): Promise<SearchData> =>
			json(
				apiRequestGet({
					url: "search/spotify",
					data: {
						query: query,
						limit: String(limit),
					},
					accept: APPLICATION_JSON,
				}),
			),
		youtube: (query: string, limit: number): Promise<SearchData> =>
			json(
				apiRequestGet({
					url: "search/youtube",
					data: {
						query: query,
						limit: String(limit),
					},
					accept: APPLICATION_JSON,
				}),
			),
	},

	me: {
		get: (): Promise<FullUser> =>
			json(
				apiRequestGet({
					url: "me/get",
					accept: APPLICATION_JSON,
				}),
			),
		setName: (name: string) =>
			empty(
				apiRequest({
					url: "me/set-name",
					method: "POST",
					data: {
						name: name,
					},
				}),
			),
		invalidateSessions: () =>
			empty(
				apiRequest({
					url: "me/invalidate-sessions",
					method: "POST",
				}),
			),
		changePassword: (oldPassword: string, newPassword: string) =>
			empty(
				apiRequest({
					url: "me/change-password",
					method: "POST",
					data: {
						oldPassword,
						newPassword,
					},
				}),
			),
	},

	admin: {
		resource: {
			reanalyze: (id: string) =>
				empty(
					apiRequest({
						url: "admin/resource/reanalyze",
						method: "POST",
						data: id,
					}),
				),
		},
		list: {
			changeInfo: (id: string, name: string, kind: ListKind, editors: ListEditors) =>
				empty(
					apiRequest({
						url: "admin/list/change-info",
						method: "POST",
						data: {
							listId: id,
							name: name,
							kind: kind,
							editors: editors,
						},
					}),
				),
			changeOwner: (id: string, newOwner: string) =>
				empty(
					apiRequest({
						url: "admin/list/change-owner",
						method: "POST",
						data: {
							listId: id,
							owner: newOwner,
						},
					}),
				),
			remove: (id: string) =>
				empty(
					apiRequest({
						url: "admin/list/remove",
						method: "DELETE",
						data: {
							listId: id,
						},
					}),
				),
		},
		user: {
			listInactive: (): Promise<User[]> =>
				json(
					apiRequestGet({
						url: "admin/user/list-inactive",
					}),
				),
			removeInactive: (loginName: string) =>
				apiRequest({
					url: "admin/user/remove-inactive",
					method: "DELETE",
					data: { loginName },
				}),
			activate: (loginName: string) =>
				apiRequest({
					url: "admin/user/activate",
					method: "POST",
					data: { loginName },
				}),
			changePermissions: (id: string, permissions: Permissions) =>
				apiRequest({
					url: "admin/user/change-permissions",
					method: "POST",
					data: { id, permissions },
				}),
			invalidateSessions: (id: string) =>
				apiRequest({
					url: "admin/user/invalidate-sessions",
					method: "POST",
					data: { id },
				}),
			setName: (id: string, name: string) =>
				apiRequest({
					url: "admin/user/set-name",
					method: "POST",
					data: { id, name },
				}),
			changePassword: (id: string, newPassword: string) =>
				apiRequest({
					url: "admin/user/change-password",
					method: "POST",
					data: { id, newPassword },
				}),
			get: (id: string): Promise<FullUser> =>
				json(
					apiRequestGet({
						url: "admin/user/get",
						data: { id },
					}),
				),
		},
		player: {
			pause: (pause: boolean) =>
				apiRequest({
					url: "admin/player/pause",
					method: "POST",
					data: { pause },
				}),
			next: (itemId: string) =>
				empty(
					apiRequest({
						url: "admin/player/next",
						method: "POST",
						data: { itemId },
					}),
				),
		},
	},
};

export function tranlateError(
	reason: string,
	translation: {
		[key: string]: string;
	},
): string {
	return reason in translation ? translation[reason] : `Unknown reason ${reason}.`;
}

export function defaultResponseError(xhr: Response): string {
	if (xhr.status >= 500) {
		return `Leierkasten is currently fucked. Please try again later. (status code ${xhr.status})`;
	} else {
		return `${xhr.statusText} (status code ${xhr.status})`;
	}
}

export function makeErrorText(
	xhr: Response,
	translation: {
		[key: string]: string;
	},
): Promise<string> {
	return xhr.json().then(
		value => tranlateError(value.reason as string, translation),
		() => defaultResponseError(xhr),
	);
}
